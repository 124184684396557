<template>
  <div>
    <ToolBar style="margin-bottom: 20px"/>
<!--    <router-view/>-->
  </div>
</template>

<script>
import ToolBar from '@/components/ToolBar.vue';

export default {
  name: 'index',
  components: {
    ToolBar,
  },
  data() {
    return {
      list: [
        { label: 'Города', path: 'region', child: [] },
        { label: 'Склады', path: 'warehouses', child: [] },
        { label: 'Ед.измерений', path: 'measures', child: [] },
        { label: 'Валюта', path: 'currency', child: [] },
        { label: 'Цвета', path: 'colours', child: [] },
        { label: 'Статусы', path: 'status', child: [] },

        { label: 'Ошибки', path: 'errors', child: [] },

      ],
    };
  },
  created() {
    this.$store.dispatch('actionSetMenu', this.list);
  },
};
</script>

<style>

</style>
